import React from 'react';
import Link from '../Link';

const Logo = () => (
  <span className="site-logo-wrapper">
    <Link href="/">
      <a className="site-logo visible-desktop">
        <img src="/static/Gifts/images/cw_logo.svg" alt="Concern Gifts" width={130} height={44} />
      </a>
    </Link>
    <Link href="/">
      <a className="site-logo visible-mobile">
        <img src="/static/Gifts/images/cw_logo.svg" alt="Concern Gifts" width={90} height={30} />
      </a>
    </Link>
  </span>
);

export default Logo;
